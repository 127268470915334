import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Dropdown = (props) =>{

    
return (
    <FormControl fullWidth size="small">
    <InputLabel id={props.labelId}  shrink={props.shrink }  >{props.label}</InputLabel>
    <Select
        
        className="dropdown"
        
        disabled={props.disabled}
        labelId={props.labelSelectId}
        id={props.dropdownId}
        value={props.value}
        label={props.label}
        onChange={(event)=>props.onChange(event.target.value )}
    >
        {props.options.map(option => (
        <MenuItem className="dropdown"  key={option.value} value={option.value}>{option.text}</MenuItem>
      ))}
        
    </Select>
    </FormControl>
    ); 
}

export default Dropdown; 