import BookmarkIcon from '@mui/icons-material/Bookmark';
import Button from '@mui/material/Button'; 
import Toolbar from '@mui/material/Toolbar'; 

import React from "react"; 
import SearchContainer from "./Search/SearchContainer";
import Link from "@mui/material/Link"


import { createTheme } from "@mui/material";


const Header = (props) => {

    return (<div class="header">
       <div id="top-menu">
       <nav>
            <ul>
                <li>
                    <a href="/" class="header-menu" >Casa</a>
                </li>
                <li>
                    <a href="/Contact" class="header-menu" color="#F5DEB3" underline='none'>Contacto</a>
                </li>
                <li>
                    <a href="/Download" class="header-menu" color="#F5DEB3" underline='none'>Descargar</a>
                </li>
                <li>
                <Button  id="sign-up"component="button"
                        variant="Icon"
                        onClick={() => {
                        }}><BookmarkIcon  />Sign In</Button>    
                </li>                
            </ul>
        </nav>
    </div>
    <div class="banner" >
        
        <h1 id="header-title">Sagrada Biblia</h1>
        <h2 id="sub-title">1602</h2>
    </div>
        <SearchContainer handleSearchSubmit={props.handleSearchSubmit} />
    </div> ); 
}

export default Header; 