import Button from '@mui/material/Button'; 
import Icon from '@mui/material/Icon';
import { PropaneSharp } from '@mui/icons-material';
import Grid from '@mui/material/Grid'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {  Link } from 'react-router-dom';
import { PropTypes } from 'prop-types'; 

const PreviousAndNextButtons = (props) => {

    return (
        <div class="next-previous-container">
            <Grid container spacing={1}>
            <Grid item xs={6} md={6}>
                <Button id="previous-chapter" as={Link} to={`/search/${props.previousChapter}`}  >
                <ArrowBackIosIcon className='chapter-arrow-icon' />
                capítulo anterior
                    </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                <Button id="next-chapter" as={Link} to={`/search/${props.nextChapter}`}  >
                próximo capítulo<ArrowForwardIosIcon className='chapter-arrow-icon' />
                </Button>
                </Grid>
            </Grid>
        </div>
            
    ); 
}



export default PreviousAndNextButtons;