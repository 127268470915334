import { BrowserRouter, Routes, Route } from "react-router-dom"
import Chapter from '../Content/Chapter/Chapter'
import Contact from '../Content/Contact/Contact'
import Home from '../Content/Home/Home'
import Download from '../Content/Download/Download'

import BibleService from '../../Service/Bible'; 

import { useEffect, useState } from "react"; 

const Content = () => {
const [lookup, setLookup] = useState([]); 
useEffect( ()  => {
  const fetchLookup = async () => {
    try {
      const response = await BibleService.getLookup();
      setLookup(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchLookup();
}, [])

    return (
    <Routes>
    <Route path="search/*" element={<Chapter lookup={lookup} />} />
    <Route path="Download" element={<Download lookup={lookup} />} />
    <Route path="Contact" element={<Contact lookup={lookup} />} />
    <Route path="/" element={<Home lookup={lookup} />} />
  </Routes>);
    
}

export default Content; 