import React, { useState, useEffect } from 'react';
import './TextFader.css'; // For the CSS styles

const TextFader = (props) => {
  const interval = 10000; 
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const fadeOut = setTimeout(() => setFade(false), interval - 1200); // Start fade out
    const changeText = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % props.textArray.length);
      setFade(true); // Fade in new text
    }, interval);

    return () => {
      clearTimeout(fadeOut);
      clearInterval(changeText);
    };
  }, [currentTextIndex, interval]);

  return (
    <div className={`fader-text ${fade ? 'fade-in' : 'fade-out'}`}>
        <p class="fader-text">
            <br/><a  href={props.textArray[currentTextIndex].link} title="Verse of the Day">
                “<span itemprop="description" >{props.textArray[currentTextIndex].text}</span>”
                 <br/><small itemprop="name">{props.textArray[currentTextIndex].source}</small></a></p>
    </div>
  );
};

export default TextFader;
