import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import './Download.css';

const Download = () => {
    return (<div class="home-content">
        <ul>
            <li> <a href="../../../Assets/Document/scanned_bible.pdf" download><PictureAsPdfIcon  />Scanned PDF </a>   </li>
            <li> <a href="../../../Assets/Document/bible.pdf" download><PictureAsPdfIcon  /> PDF </a>   </li>
            <li> <a href="../../../Assets/Document/bible.docx" download> Documento de Word </a>  </li>
        </ul>
        
    </ div>)

}

export default Download; 
