import './Home.css'; 
import TextFader from './TextFader';
import opwnBiblwImGW from '../../../Assets/Image/open-bible.png'; 

const textFaderArray = [{text: `Entonces los que temen á Jehová hablaron cada uno á su
                        compañero; y Jehová escuchó y oyó, y fué escrito libro de memoria delante de él
                        para los que temen á Jehová, y para los que piensan en su nombre.`, 
                        source: 'Malaquías 3:16', 
                        link: 'search/Malachi/3/16'}, 
                        {text: `EN esto, juntándose muchas gentes, tanto que unos á otros se
hollaban, comenzó á decir á sus discípulos, primeramente: Guardaos de la
levadura de los Fariseos, que es hipocresía....`, 
                            source: 'Lucas 12:1', 
                            link: 'search/Lucas/12/1'}, 
                        {text: `Porque nada hay encubierto, que no haya de ser descubierto; ni oculto, que no
                                haya de ser sabido.`, 
                            source: 'Lucas 12:2', 
                            link: 'search/Lucas/12/2'},
                        {text: `Y dijo: Anda, Daniel, que estas palabras están cerradas y selladas
                                hasta el tiempo del cumplimiento.`, 
                        source: 'Daniel 12:9', 
                        link: 'search/Daniel/12/9'}, 
                         ]

const Home = (props) => {
    return (
        <div id="home-content-container">
            <TextFader textArray={textFaderArray} />
        <img src={opwnBiblwImGW} alt="bible" width="350px" />
        
        <p class="summary">
        La Palabra (La Biblia), el libro del Padre Celestial es un regalo que el Padre Celestial
nos ha dado desde el principio de los tiempos. Su Palabra nos da la sabiduría para
sobrevivir y también para perseverar hasta el fin en estos últimos días, y muchos
hombres malvados en el poder que supervisan la distribución de la Biblia han
escondido intencionalmente libros de la Biblia (libros Apócrifos), y de la Biblia original
con revisiones que engañosamente cambiaron la Palabra traducida.
</p>
<p class="summary">Así que aquí está la Palabra del Padre Celestial que las profecías nos hacen saber que
los hombres malvados no podían pensar que podían mantener oculta la palabra del
Altísimo cuando no hay nada oculto que no se sepa.

        </p>
        </div>
        
    )
}

export default Home; 