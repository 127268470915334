
import Grid from '@mui/material/Grid'; 
import Box from '@mui/material/Box'; 
import { IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import React, { useState, useEffect } from 'react'; 
import PropTypes from 'prop-types'; 

import Dropdown from '../../Shared/Dropdown/Dropdown';

import Bible from '../../../Service/Bible'; 
import { useNavigate, useLocation } from "react-router-dom"; 




const SearchByVerse = (props) => {
  let error = ""; 
  const navigate = useNavigate()   

  const bibliaEspanol = [
      { value: "Genesis", text: "Génesis" },
      { value: "Exodus", text: "Éxodo" },
      { value: "Leviticus", text: "Levítico" },
      { value: "Numbers", text: "Números" },
      { value: "Deuteronomy", text: "Deuteronomio" },
      { value: "Joshua", text: "Josué" },
      { value: "Judges", text: "Jueces" },
      { value: "Ruth", text: "Rut" },
      { value: "1 Samuel", text: "1 Samuel" },
      { value: "2 Samuel", text: "2 Samuel" },
      { value: "1 Kings", text: "1 Reyes" },
      { value: "2 Kings", text: "2 Reyes" },
      { value: "1 Chronicles", text: "1 Crónicas" },
      { value: "2 Chronicles", text: "2 Crónicas" },
      { value: "Ezra", text: "Esdras" },
      { value: "Nehemiah", text: "Nehemías" },
      { value: "Esther", text: "Ester" },
      { value: "Job", text: "Job" },
      { value: "Psalms", text: "Salmos" },
      { value: "Proverbs", text: "Proverbios" },
      { value: "Ecclesiastes", text: "Eclesiastés" },
      { value: "Song of Solomon", text: "Cantares" },
      { value: "Isaiah", text: "Isaías" },
      { value: "Jeremiah", text: "Jeremías" },
      { value: "Lamentations", text: "Lamentaciones" },
      { value: "Ezekiel", text: "Ezequiel" },
      { value: "Daniel", text: "Daniel" },
      { value: "Hosea", text: "Oseas" },
      { value: "Joel", text: "Joel" },
      { value: "Amos", text: "Amós" },
      { value: "Obadiah", text: "Abdías" },
      { value: "Jonah", text: "Jonás" },
      { value: "Micah", text: "Miqueas" },
      { value: "Nahum", text: "Nahúm" },
      { value: "Habakkuk", text: "Habacuc" },
      { value: "Zephaniah", text: "Sofonías" },
      { value: "Haggai", text: "Hageo" },
      { value: "Zechariah", text: "Zacarías" },
      { value: "Malachi", text: "Malaquías" },
      { value: "Matthew", text: "Mateo" },
      { value: "Mark", text: "Marcos" },
      { value: "Luke", text: "Lucas" },
      { value: "John", text: "Juan" },
      { value: "Acts", text: "Hechos" },
      { value: "Romans", text: "Romanos" },
      { value: "1 Corinthians", text: "1 Corintios" },
      { value: "2 Corinthians", text: "2 Corintios" },
      { value: "Galatians", text: "Gálatas" },
      { value: "Ephesians", text: "Efesios" },
      { value: "Philippians", text: "Filipenses" },
      { value: "Colossians", text: "Colosenses" },
      { value: "1 Thessalonians", text: "1 Tesalonicenses" },
      { value: "2 Thessalonians", text: "2 Tesalonicenses" },
      { value: "1 Timothy", text: "1 Timoteo" },
      { value: "2 Timothy", text: "2 Timoteo" },
      { value: "Titus", text: "Tito" },
      { value: "Philemon", text: "Filemón" },
      { value: "Hebrews", text: "Hebreos" },
      { value: "James", text: "Santiago" },
      { value: "1 Peter", text: "1 Pedro" },
      { value: "2 Peter", text: "2 Pedro" },
      { value: "1 John", text: "1 Juan" },
      { value: "2 John", text: "2 Juan" },
      { value: "3 John", text: "3 Juan" },
      { value: "Jude", text: "Judas" },
      { value: "Revelation", text: "Apocalipsis" }
    ];
  
  const path = useLocation();
  const parameters = path.pathname.replace('/', '').split('/');
  let selection = parameters[0] !== "search" ? {
    book: null,  
    chapter: null,  
    verse: null, 
  } :
  {
    book: parameters[1],  
    chapter: parameters[2],  
    verse: parameters[3], 
  } 
  const  [searchFields, setSearchFields] = useState(
      {
        searchResults: [], 
        verseOptions: [],
        chapterOptions: [],
        selection: selection
      }
  ); 
    

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await Bible.getChaptersAndVerses(parameters[1], parameters[2]);
        setSearchFields({
          searchResults: response.data,  
          verseOptions: response.data.filter(item =>item.chapter===1)
            .map(verse => ({ value: verse.number, text: verse.number })),
          chapterOptions: response.data.map(result => result.chapter)
          .filter((value, index, array) => array.indexOf(value) === index)
          .map(item => ({value: item,  text: item})),
          selection: selection
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
  }, [path])


   
    
    const bookDropdownOnChange = async (value) => {
        await resetChapterAndVerse(value, 1); 
    };

    const chapterDropdownOnChange = async (selectedChapter) => {
      console.log(searchFields)
      setSearchFields({...searchFields,
        searchResults: searchFields.searchResults,
        chapterOptions:  searchFields.chapterOptions, 
        verseOptions: searchFields.searchResults.filter(item =>item.chapter===selectedChapter)
        .map(verse => ({ value: verse.number, text: verse.number })),  
        selection: {
          book: searchFields.selection.book,
          chapter: selectedChapter, 
          verse: 1
        } 
      })     
      

    }; 

    const verseDropdownOnChange = (value) => {
        setSearchFields({
            ...searchFields,  
            selection: {
              book: searchFields.selection.book,
              chapter: searchFields.selection.chapter, 
              verse: value
            }, 
        })
    };

    const resetChapterAndVerse = async(book) => {
      let result = await Bible.getChaptersAndVerses(book); 
      if(result && result.data.length > 0)
      {
        setSearchFields({
          searchResults: result.data,  
          verseOptions: result.data.filter(item =>item.chapter===1)
            .map(verse => ({ value: verse.number, text: verse.number })),
          chapterOptions: result.data.map(result => result.chapter)
          .filter((value, index, array) => array.indexOf(value) === index)
          .map(item => ({value: item,  text: item})),
         selection: {
            book: book,  
            chapter: 1,  
            verse: 1, 
        }})  
      }
      else 
      {
        result = [];
        setSearchFields({
          verseOptions: result.map(item => item.verse),
          chapterOptions: result.map(item => item.chapter),
         selection: {
            book: book,  
            chapter: 1,  
            verse: 1, 
        }}) 
      }
      
    };

    const handleSearchSubmit = () => {
      navigate(`search/${searchFields.selection.book}/${searchFields.selection.chapter}/${searchFields.selection.verse}`)
       
    };


    return (
        <div class="search-by-verse">
            <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={4} md={6}>
          <Dropdown  
          labelSelectId="search-book"
          shrink={searchFields.selection.chapter !== null}
          id="search-book"
          value={searchFields.selection.book}
          labelId={searchFields.selection.chapter !== null || parameters.length > 3 ? 'input-label-shrink': 'input-label-no-value'}
          label="Elegir un libro"
          onChange={bookDropdownOnChange}
          options={bibliaEspanol} />
        </Grid>
        <Grid item xs={3} md={2}>
        <Dropdown  
          labelSelectId="search-chapter"
          id="search-chapter"
          disabled={searchFields.selection.book === null}
          value={searchFields.selection.chapter}
          shrink={searchFields.selection.chapter !== null}
          labelId={searchFields.selection.chapter !== null ? 'input-label-shrink': 'input-label-no-value'}
          label="Capitulo"
          onChange={chapterDropdownOnChange}
          options={searchFields.chapterOptions} />
        </Grid>
        <Grid item xs={3} md={2}>
        <Dropdown  
          labelSelectId="search-chapter"
          id="search-chapter"
          disabled={searchFields.selection.chapter === null}
          value={searchFields.selection.verse}
          shrink={searchFields.selection.chapter !== null}
          label="Versículo"
          labelId={searchFields.selection.chapter !== null ? 'input-label-shrink': 'input-label-no-value'}
          onChange={verseDropdownOnChange}  
          options={searchFields.verseOptions} />
        </Grid>
        <Grid item xs={1} md={2}>
        <Button aria-label="search"  disabled={searchFields?.selection?.chapter === null}
        variant="contained"
          onClick={ handleSearchSubmit}>
          <SearchIcon />
        </Button>
        </Grid>
      </Grid>
    </Box>
        </div>
    ); 
}

export default SearchByVerse;