
import './App.css';
import Header from './Components/Header/Header';
import Content from './Components/Content/Content';
import Footer from './Components/Footer/Footer';

import { BrowserRouter } from "react-router-dom";



function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <div class="main-container">
        <Header />
      </div>  
      <div class="center-body">
        <div class='content'>
          <Content />  
        </div>
      </div>
      <div class="footer-container">
        <Footer />
      </div>    
    </div>
    </BrowserRouter>
  );
}

export default App;
