import SearchByVerse from "./SearchByVerse.js";
const SearchContainer = (props) => {
    
    return (
        <section class="search-options">
            <div class="main">
                <div class="left_search">
                    
                </div>
                <div class="right_search">
                    <SearchByVerse  />
                </div>

            </div>
        </section>
    ); 
}

export default SearchContainer; 