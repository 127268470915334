import React, { useState } from 'react';
import { TextField, Button, Box, Alert, Snackbar } from '@mui/material';

import Email   from '../../../Service/Email.js'


const Contact = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [email, setEmail] = useState({address: '', message: '', isValid: false, open: false  }
  );
  const handleClose = (event, reason) => {
    setEmail({...email, open: false});
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      const emailSent = await Email.contactEmail(email); 
      if(emailSent.data)
      {
         setEmail({address: '', message: '', isValid: false, open: true  }); 
      }

    } catch (error) {
      console.log(error)
    }
    
  };

  const validateAndSetEmail = (value, field) => {

    let tempEmail = email;  
    tempEmail[field] = value; 
    let isValid = emailRegex.test(tempEmail.address) && tempEmail.message.trim().length > 3;
    setEmail({address: tempEmail.address,
            message: tempEmail.message, 
            isValid: isValid 
    })
  };



  return (
    <> <div ><Snackbar
    anchorOrigin={{  vertical: 'top', horizontal: 'right'  }}
    open={email.open}
    autoHideDuration={3000} // 3 seconds
    onClose={handleClose}
  >
    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
      Email sucessfully sent!
    </Alert>
  </Snackbar>
  <div><h3>¿QUIERES APRENDER MÁS O TIENES UNA PREGUNTA?</h3>
        </div>
              <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      maxWidth: '400px',
                      margin: 'auto',
                      padding: '20px',
                  }}
              >
                  <TextField
                      label="Email"
                      type="email"
                      variant="outlined"
                      required
                      value={email.address}
                      onChange={(e) => validateAndSetEmail(e.target.value, 'address')}
                      error={!emailRegex.test(email.address) && email.address.trim().length > 2}
                      helperText={!emailRegex.test(email.address) && email.address.trim().length > 2 ? 'correo electrónico no válida' : ' '} />
                  <TextField
                      label="Mensaje"
                      variant="outlined"
                      multiline
                      rows={4}
                      required
                      value={email.message}
                      onChange={(e) => validateAndSetEmail(e.target.value, 'message')}
                      error={email.message.trim().length < 3 && email.message.trim() !== ''}
                      helperText={email.message.trim().length < 3 && email.message.trim() !== '' ? 'por favor complete el mensaje' : ' '} />
                  <Button type="submit" disabled={!email.isValid} variant="contained" color="primary">
                      Send
                  </Button>
              </Box>
          </div></>
  );
};

export default Contact  ;
